const selector = '.js-overlay-toggle'

function init() {
  const toggles = document.querySelectorAll(selector)
  let background, current, first = true

  const toggle = () => {
    const active = current.classList.contains('is-active')

    if (first) {
      first = false
      background = document.createElement('div')
      background.classList.add('overlay-background')
      document.body.append(background)

      background.addEventListener('click', () => {
        toggle() // close overlay when background is clicked
      })
    }

    document.body.append(current)

    // wait for next frame, to be able to animate with css
    setTimeout(() => {
      document.body.classList.toggle('overlay-visible');
      document.body.classList.toggle('noscroll');
      background.classList.toggle('is-active')
      current.classList.toggle('is-active')
      current.setAttribute('aria-expanded', active)
    }, 0);
  }

  toggles.forEach(el => {
    const overlay = document.getElementById(el.dataset.overlayId)

    if (overlay) {
      el.addEventListener('click', () => {
        current = overlay
        toggle()
      })
    } else {
      console.warn('overlay with id ' + el.dataset.overlayId + ' not found!')
    }
  })
}

if (document.querySelector(selector) !== null) {
  init()
}
